
.loader {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  ion-spinner {
    margin-right: 10px;
  }
}

.note-modal {
  -moz-box-shadow: -4px 4px 5px #b6b6b6;
  -webkit-box-shadow: -4px 4px 5px #b6b6b6;
  box-shadow: -4px 4px 5px #b6b6b6;
}


.note-button {
  width: 100%;
  background: rgb(239, 239, 239);
  text-align: center;
  border-radius: 3px;
  background: #ccd3d3;
  color: #7b7979
}
