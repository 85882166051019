

.person-wrapper {
  max-width: 190px;
}


.details-container {
  line-height: 40px;
}

.location-box {
  padding-left: 15px;
  border-left: 0.3rem solid;
}

.type-detail {
  color: grey
}

.location-icon {
  color: black !important
}

.location-text {
  text-decoration: none !important;
  color: #000 !important;
}

.interactive-segment {
  --background: #fff;
}

.interactive-segment-btn {
  --background: #fff !important;
  --color: 3 d5a59 !important;
  --color-checked: #3d5a59 !important;
  --background-checked: #fff !important;
}

.session-head {
  display: inline-block;
  border-bottom: none;
  position: relative;

  .code-wrapper {
    position: absolute;
    left: 0px;
    height: 100%;
    width: 8px;
    border-radius: 5px;
  }


  .item-header-gradient {
    position: absolute;
    //z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url('../../assets/shadow.png') no-repeat bottom center;
    background-size: cover;
  }

  .title {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }

  .type {
    font-size: 0.9rem;
    display: block;
    line-height: 1rem;

    strong {
      display: inline-block;
      font-size: 0.7rem;
    }
  }

  .time {
    font-size: 0.9rem;
  }
}

$main-color: #ba2526;

.session-corner-annotation {
  background-image: linear-gradient(to right, rgb(197, 50, 50), rgb(179, 68, 68));
  position: absolute;
  top: -45px;
  right: -115px;
  width: 180px;
  height: 150px;
  -webkit-transform: rotate(45deg);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.annotation-text {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  color: white;
}

.content-left {
  .action-buttons {
    .actn-icon {
      font-size: 1.3rem !important;
    }

    .actn-text {
      font-size: 0.7rem !important;
      line-height: 0.8rem !important;
    }
  }

  .stream-container {
    width: 100%;
    //max-width: 1100px;

    .stream {
      position: relative;
      width: 100%;
      aspect-ratio: 16/9;

      .info-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px dashed grey;

        .info {
          flex: 0 0 400px;
          font-size: 2rem;
          line-height: 2.2rem;
          color: grey;
          font-weight: bold;
        }
      }
    }
  }
}

@media(max-width: 767px) {
  .person-item {
    max-width: 100%;
    border-radius: 0;
    border: none;
  }

  .description-box {
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }


  .description-button {
    position: relative;
    left: 20px;
    top: 0px;
    background: $main-color;
    border: none;
    margin-bottom:10px
    // top: 0px;
    //opacity: 40% !important;
  }
}

@media(min-width: 768px) {
  .description-button {
    display: none !important;
  }
}

