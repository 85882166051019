
.person-col {
  max-width: 200px;
}

.person-item {
  text-decoration: none;
  max-width: 200px;
  color: #000;
  width: 170px;
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 18%) !important;
  background: #ba2526;

  .card-body {
    font-size: 0.9rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


    .card-title {
      line-height: 1rem;
      color: #fff;
    }

    .card-title-sub {
      color: #fff;
    }
    .speaker-line {
      color: #fff;
    }
  }

  .card-text {
    max-width: 85%;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  .person-image {
    background: #fff;
    object-fit: cover;
    object-position: top;
    aspect-ratio: 1/1;
    min-width: 50px;
  }
}


@media(max-width: 767px) {
  .person-col {
    max-width: 100%;
  }
  .person-item {
    max-width: 100%;
    width: 100%;
    border-radius: 0;
    border: none;
    box-shadow: none !important;
    background: #fff;

    .card-body {
      .card-title {
        color: #000;
      }

      .card-title-sub {
        color: #000;
      }

      .speaker-line {
        color: #000;
      }
    }

    .person-image {
      object-fit: cover;
      object-position: top;
      aspect-ratio: 1/1;
      width: 50px;
    }
  }
}
